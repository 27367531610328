#record-track-container{
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

#record-track-info-div{
    /* background-color: purple; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    height: 85%;
}

#display-record-card-div{
    /* background-color: green; */
    color: rgb(190, 190, 190);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2%;
    margin-top: 2%;
    margin-left: 1%;
    width: 100%;
    height: 65vh;
}

/* #recording-pause-btn {
    background-color: #259FB1;
    border-color: #259FB1;
    border-radius: 0px;
    width: 100%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
}
#recording-pause-btn:hover{
    background-color:#108193;
    border-color: #108193;
    text-decoration: underline;
} */

#record-publish-buttons-div{
    /* background-color: orange; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 3%;
    width: 100%;
    margin-top: 2%;
}

#record-cancel-btn {
    background-color: #aaaaaa;
    border-color: #aaaaaa;
    border-radius: 0px;
    width: 40%;
    height: 7vh;
}
#record-cancel-btn:hover{
    background-color: #7a7a7a;
    border-color: #7a7a7a;
    text-decoration: underline;
}

#record-publish-btn {
    background-color: #F8607C;
    border-color: #F8607C;
    border-radius: 0px;
    width: 40%;
    height: 7vh;
    /* margin-top: 1%; */
}
#record-publish-btn:hover{
    background-color:#e33051;
    border-color: #e33051;
    text-decoration: underline;
}


/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    #record-track-info-div{
        width: 100%;
    }

    #record-track-container{
        margin: 0px;
        padding: 0px;
        width: 100%;
    }
}