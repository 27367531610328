#record-container{
    /* background-color: orange; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
}

#record-container-body{
    /* background-color: rgb(11, 11, 11); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    /* height: 100%; */
}

#script-div{
    /* background-color: #830000; */
    width: fit-content;
}

.devBox {
    color: white;
}

.setupGuide {
    color: white;
    text-align: center;
}

#record-btns-div{
    background-color:#0000004f;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: fit-content;
    padding: 1%;
    align-items: center;
    justify-content: center;
}

#recording-play-btn {
    background-color: #B40909;
    border-color: #B40909;
    border-radius: 0px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
}
#recording-play-btn:hover{
    background-color:#830000;
    border-color: #830000;
    text-decoration: underline;
}

#recording-stop-btn {
    background-color: #259FB1;
    border-color: #259FB1;
    border-radius: 0px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
}
#recording-stop-btn:hover{
    background-color:#108193;
    border-color: #108193;
    text-decoration: underline;
}

#download-midi-btn{    
    color: white;       
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
}
#download-midi-btn:hover{
    color: #c4c4c4;
}


/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    #record-container-body{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #script-div{
        width: 100%;
    }

}