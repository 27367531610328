#cardCarousel-container{
    width: 50vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}

#cardCarousel-container .carousel.carousel-slider {
    width: 45vw!important;
}

#cardCarousel-container .carousel .carousel-status{
    display: none;
} 

.carousel-control-prev, .carousel-control-next{
    display: none;
} 

.card-display{
    height: 60vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0e0e0e;
    background-image: url('');
    background-size: 100% 150% !important;
    background-position: center center !important;
    background-repeat: no-repeat;
    color: white;
    word-wrap: break-word;

}

#play-pause-button{
    background-color: #259FB1;
    border-color: #259FB1;
    border-radius: 0px;
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10%;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    #cardCarousel-container{
        width: 100%;
    }

    #cardCarousel-container .carousel.carousel-slider {
        width: 80vw!important;
        margin-bottom: 10px;
    }
}
