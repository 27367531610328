/* CSS for Image Search Modal */

#image-search-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#image-search-footer{
    display: flex;
    flex-direction: column;
    color: rgb(194, 194, 194);
    width: 100%;
    height: 100%
}

.searchbar-div{
    color: white;
}
#image-modal-title{
    color: white;
}

#searchTitle{
    color: white
}

#search-image-btn{
    background-color: #43ABBB;
    border-color: #43ABBB;
    border-radius: 5px;
}

#search-image-btn:hover{
    background-color: #00a1bac1;
    border-color: #318a97;
    text-decoration: underline;
}


.search-image-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 4%;
}

.search-image {
    background-color: #565656;
    filter: drop-shadow(0px 4px 4px #181818);
    width: 14vw;
    height: fit-content;
    border-radius: 10px;  
    margin-bottom: 20px;
    padding: 5px;
    display: flex;
    justify-content: center;
}
.search-image:hover{
    cursor: pointer;
    background-color: #F8607C;
}


/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    .modal-dialog{
        padding-top: 0px;
    }
   .modal-content{
        height: fit-content;
   }

   .search-image{
        width: 30%;
   }
}