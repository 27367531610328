.upload-track-btn{
    background-color: #F8607C;
    border-color: #F8607C;
    color: white;
    width: 30%;
    min-width: fit-content;
}

.upload-track-btn:hover{
    background-color:#e33051;
    border-color: #e33051;
    text-decoration: underline;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    .upload-track-btn{
        width: 100%;
    }
}