/* CSS for Home */

#home-container {
    /* background-color: red; */
    max-width: 100%;
    max-height: 100%;
    /* height: 100vh; */
    display: block;
    padding: 0px;
}

#carousel-container {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0px;
}

#carousel-container .carousel.carousel-slider {
    width: 100%!important;
}

#carousel-container .carousel .carousel-status{
    display: none;
} 


/* CSS for Tracks Section on Home */
#featured-tracks-heading { 
    color: #ffffff;
    text-decoration: none !important;
    margin-top: 2%;
    padding-left: 2%;
}

#track-card-container {
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: none;
    width: 100%;
    /* margin: 0; */
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

}
