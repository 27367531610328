/* CSS for Track Modal */
.modal-dialog{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.modal-content{
    background-color: #707070;
    filter: drop-shadow(0px 4px 4px #181818);
    min-width: 70vw;
    width: fit-content;
    min-height: 70vh;
    height: fit-content;
}

.modal-backdrop.show{
    background-color: rgb(0, 0, 0);
}

.modal-container-header{
    background-color: #8E8E8E;
    border-color: #8E8E8E;
    color: white;
    width: 100%;
}

.modal-container-body{
    display: flex;
    flex-direction: row;
    background-color: #8E8E8E;
    border-color: #8E8E8E;
    color: white;
    width: 100%;
    min-width: 65vw;
    height: fit-content;
    justify-content: center;
    align-items: center;
}

.modal-container-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #707070;
    border-color: #525252;
    color: white;
    width: 100%;
}

#modal-container-footer-1{
    display: flex;
    justify-content: left;
    width: 30%;
    min-height: 90%;
}
#modal-container-footer-2{
    display: flex;
    justify-content: end;
    width: 65%;
    min-height: 90%;
    gap: 1%;
}

#modal-track-cover-div{
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 30vw;
    /* padding: 0.5%; */
    /* background-color: red; */
}

#edit-track-cover-div{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.522);
    z-index: 2;
    width: 30vw;
    height: 30vw;
}

#track-cover-upload-label{
    /* background-color: red; */
    width: 90%;
}

#track-cover-upload{
    width: 90%;
}

.modal-track-cover{
    /* background-color: #067081; */
    width: 30vw;
    height: 30vw;
    filter: drop-shadow(0px 2px 4px #585858);
}

#modal-track-text-div{
    width: 55%;
    padding: 3%;
    padding-top: 7%;
    padding-left: 5%;
}

#play-btn{
    display: flex;
    justify-content: center;
    background-color: #F8607C;
    border-color: #F8607C;
    border-radius: 0px;
    width: 40%;
    min-width: fit-content;
    height: fit-content;
    margin-top: 5%;
    padding: 2%;
}
#play-btn:hover{
    background-color:#e33051;
    border-color: #e33051;
    text-decoration: underline;
}

#favorites-text {
    column-gap: 1%;
    margin-top: 2%;
    color: rgb(207, 207, 207);
}

#hidden-track-text{
    color: black;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: larger;
}

/* #track-title-text{
} */

#track-author-text{
    font-weight: 350;
    font-size: larger;
}

.modal-track-icons{
    margin-right: 10px;
}

#modal-track-play-icon{
    width: 20%;
}
.modal-btn{
    background-color: #A9A9A9;
    border-color: #626262;
    color: white;
    font-weight: 400;
    border-radius: 0px ;
}
.modal-btn:hover{
    background-color: #8b8b8b;
    border-color: #525252;
    text-decoration: underline;
}
.modal-btn:active{
    background-color: #787878;
    border-color: #333333;
    text-decoration: underline;
}

.modal-btn-public{
    background-color: #259FB1;
    border-color: #259FB1;
    color: white;
    font-weight: 400;
    border-radius: 0px ;
}
.modal-btn-public:hover{
    background-color: #0c7b8b;
    border-color: #0c7b8b;
    text-decoration: underline;
}
.modal-btn-public:active{
    background-color: #067081;
    border-color: #085966;
    text-decoration: underline;
}

#delete-track-btn{
    background-color: #B40909;
    border-color: #B40909;
    margin-left: 3%;
}
#delete-track-btn:hover{
    background-color: #7e0000;
    border-color: #7e0000;
    text-decoration: underline;
}

#like-track-btn{
    background-color: #A9A9A9;
}
#like-track-btn:hover{
    background-color: #8b8b8b;
    border-color: #525252;
}

#favorited-heart{
    color: rgb(255, 255, 255) ;
}
#dislike-track-btn{
    background-color: #585858;
    border-color: #484848;
    color: rgb(255, 255, 255)
}
#dislike-track-btn:hover{
    background-color: #434343;
    border-color: #414141;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {

    .modal-dialog{
        display: flex;
        justify-content: center;
        padding-top: 5px;
        max-width: 100vw;
    }

    .modal-content{
        width: 80%;
    }

    .modal-container-body{
        display: flex;
        flex-direction: column;
    }

    .modal-track-cover, #modal-track-cover-div, #edit-track-cover-div {
        width: 60vw;
        height: 60vw;
    }

    #modal-track-text-div{
        width: 80%;
    }

    #hidden-track-text, #favorites-text{
        font-size: medium;
    }

    #track-author-text{
        font-size: large;
    }

    #play-btn{
        width: 100%;
        margin-top: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal-container-footer{
        display: flex;
        flex-direction: column;
    }

    #modal-container-footer-1{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    #modal-container-footer-2{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .modal-btn-public{
        width: 100%;
    }
}
