/* CSS for Basic Settings ('settings1') container */
.form-check {
    float: left;
}

.form-check label{
    display: inline-block;
    padding-right: 60px;
    white-space: nowrap;
}

.form-check label span{
    vertical-align: middle;
}

.form-check input{
    vertical-align: middle;
}

.btn-group {
    max-width: 65%;
}

.form-group {
    max-width: 100%;
    margin: 0%;
}

#checkbox-div{
    background-color: #a9a9a9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    padding: 20px;
    border-radius: 4px;
}

#main-container {
    max-width: 100%;
    max-height: 100%;
    /* height: 100vh; */
    display: block;
    justify-content: center;
    
}

#next-btn, #back-btn {
    margin: 3%;
    float: inline-end;
}

.centered-text {
    color:rgb(57, 57, 57);
    /* margin-left: 2%;     */
    text-align: center;

    
}

.settings-text {
    color:white;
    padding:.5%;
    text-align: center;
    background-color: #727272; 
}

.settings-text2 {
    color:rgb(42, 42, 42);
    /* margin-left: 2%;     */
    text-align: center;
    /* background-color: red; */
}

#board-dropdown {
    /* background-color: #0c7b8b; */
    margin-left: 2%;
}

#settings-options-div{
    background-color:rgb(202, 202, 202);
    /* color: white; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 1%;
    border-radius: 4px;
}

#OR {
    display: flex;
    justify-content: center;
    color: rgb(230, 230, 230);
}

#back-btn, #next-btn, #adv-btn {
    border-radius: 4px;
    padding: 7px 20px 7px 20px;
    width: 200px;
}

#back-btn {
    background-color: #5B5B5B;
    border-color: #5B5B5B;
    float: right;
}
#back-btn:hover {
    background-color: #323232;
    border-color: #323232;
    text-decoration: underline;
}

#next-btn {
    background-color:#259FB1;
    border-color: #259FB1;
    filter: drop-shadow(0px 4px 4px #0d3d44);
}
#next-btn:hover{
    background-color:#1e7583;
    border-color: #1e7583;
    text-decoration: underline;
    filter: drop-shadow(0px 4px 4px #0d3d44);
    text-decoration:none;
}

#adv-btn{
    background-color: #259FB1;
    border-color: #259FB1;
    filter: drop-shadow(0px 4px 4px #476770);
}
#adv-btn:hover{
    background-color: #0c7b8b;
    border-color: #0c7b8b;
    text-decoration: underline;
    filter: drop-shadow(0px 4px 4px #476770);
    text-decoration:none;
}

/* CSS for settings1 and settings2 containers */
.heading{
    color: white;
    margin: 1%;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    
    
}

#track-settings-container{
    min-width: fit-content;
    display: flex;
    justify-content: center;
    padding-bottom: 4.5%;
   
    
}
#settings-container1, #settings-container2 {
    min-width: 100%;
    width: fit-content;
    padding: 0px 0px 20px 0px;
    margin: auto;
    height: fit-content;
    position: relative;
    background-color:rgb(198, 198, 198);
    filter: drop-shadow(0px 4px 4px #181818);
    display: block;
    border-color:rgb(0, 0, 0);
    border-radius: 4px;
   
}

#settings1{
    display:block;
    min-width: 70%;
    
}

#settings2{
    display: none;
    min-width: 70%;
}

#select-device-div{
    /* background-color: white; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
 
}

/* CSS for Advanced Settings ('settings2') Container */

.adv-settings-container {
    max-width: 70%;
    /* max-height: 40%; */
    padding: 10px 10px 20px 10px;
    margin: auto;
    height: fit-content;
    position: relative;
    background-color:rgb(198, 198, 198);
    filter: drop-shadow(0px 4px 4px #181818);
    display: block;
    border-color:rgb(95, 95, 95);
    border-radius: 4px;
    /* width: fit-content !important; */
    overflow-y: auto;
    overflow-x: hidden;
    
}

.instruments-div, .instruments-other-div{
    height: 100%;
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.instrument-box, .instrument-box-other{
    display: flex;
    flex-direction: column;
    max-width: 50%;
    width: fit-content;
    height: 100%;
    padding: 15px;
    margin: 5px;

}

.instrument-box{
    background-color: #259FB1;
    color: white;
}

.instrument-box-other{
    border-color: red;
    padding: 0px 5px 5px 5px;
}

.dropdowns{
    border-color:black;
    border-radius:4px;
}

.dropdowns2{
    border-color: black;
    border-radius:4px;
}

#back-btn-adv {
    float: inline-end;
    border-radius: 4px;
    padding: 7px 20px 7px 20px;
    width: 200px;
    background-color: #D9D9D9;
    border-color: #D9D9D9;
    float: right;
    color: black
}
#back-btn-adv:hover {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    color: white;
    text-decoration: underline;
}

#random-btn-adv {
    float: inline-end;
    border-radius: 4px;
    padding: 7px 20px 7px 20px;
    width: 200px;
    background-color: #6f6f6f;
    border-color: #6f6f6f;
    float: right;
    color: white
}
#random-btn-adv:hover {
    background-color: #404040;
    background-color: #404040;
    color: white;
    text-decoration: underline;
}

/* CSS for Mobile View */
@media only screen and (max-width: 1020px) {

    .heading{
        text-align: center;
    }

    #settings-container1, #settings-container2 {
        min-width: 70%;
        width: 100%;
    }

    #settings-container2{
        margin-bottom: 5%;
    }

    #settings-options-div{
        width: 100%;
        flex-direction: column;
        align-items: start;
        padding-left: 5%;
    }

    /* Advanced Settings */
    .adv-settings-container{
        margin: 0px;
        max-width: 90%;
    }

    #random-btn-adv {
        float: none;
        margin: 0px;
        width: 100%;
    }

    .instrument-box{
        max-width: fit-content;
    }
}

@media only screen and (max-width: 600px) {
    .instruments-other-div
    {
        flex-direction: column;
        justify-content: left;
    }   
}