.form-outline {
    margin-top: 5%;
    position: relative;
    background-color: rgb(66, 61, 61);
}

.form-control { 
    float: right;
    /* margin-top: 5%; */
    text-align: left;
}

#search-container {
    position: relative;
    height: 100vh;
    padding: 40px 40px 0px 40px;
    background: rgb(76, 69, 69); 
    overflow-y: scroll;
}

#note {
    background-color: white;
}

.searchMainBody{
    /* background-color: green; */
    color: white;
    width: 100%;
    /* max-width: 80vw; */
    /* height: 100%; */
    margin: auto;
}

.searchBody{
 /* background-color: red; */
 padding: 2%;
}

.searchbar-header-div{
    background: url('../../images/bgSearch.png')/*linear-gradient(270deg, #40BBCE 0%, #008BA0 100%);*/;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35vh;
    padding: 1%;
}

.searchbar-div{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#searchTitle{
    font-weight: 500;
   
}
#searchbar{
    width: 80%;
    height: 100%;
    margin-left: 1%;
    border-color: none;
    
}

.searchResultsText{
    /* background-color: red; */
    color: #D9D9D9;
}





.search {
    --padding:14px;
    display: flex;
    align-items: center;
    padding: var(--padding);
    border-radius: 28px;
    background: #f6f6f6;
    transition: box-shadow 0.25s;
    width: 50%;
   
}

.search:focus-within {
    box-shadow: 0 0 2px rgba(0,0,0,0.75);
}
.search-input {
    font-size: 16px;
    font-family: 'Lexand', sans-serif;
    color: #333333;
    width:max-content;
    outline: none;
    border: none;
    background: transparent;
   
   
}

.search-input::placeholder,
.search-icon {
    color: rgba(0,0,0,0.5);
}