.error-msg {
    color: rgb(255, 0, 0);
    padding-top: 1%;
    max-width: 300px;
    overflow-wrap: break-word;
    margin-top: 5px;
    font-size: small;
}
.success-msg {
    color: green;
    padding-top: 1%;
    max-width: 300px;
    overflow-wrap: break-word;
    margin-top: 5px;
    font-size: small;
}

.signup-text {
    color: black;
}

#error-msg-container{
    display: flex;
    justify-content: center;
}
#main-signup-container {
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    padding: 10px;
}

#signup-container{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #CFCFCF;
    border-radius: 10px;
    padding: 40px;
}

#signup-btn-container{
    width: 100%;
    height: 13%;
    padding: 0%;
}

.formGroupExampleInput, .formGroupExampleInput2{
    border-radius: 0px;
    background-color: white;
    border-color: #CFCFCF;
}

#signup-btn{
    width: 100%;
    margin-top: 5%;
    background-color: #43ABBB;
    border-color: #43ABBB;
    border-radius: 0px;
}
#signup-btn:hover{
    background-color: #107888;
    border-color: #107888;
    text-decoration: underline;

}

#have-account-container {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: center;
    background-color: #CFCFCF;
    border-radius: 10px;
    margin-top: 4%;
    padding: 13px;
}

#login-link {
    margin-left: 5px;
    color: #0085FF;
    font-weight: 600;
}
#login-link:hover {
    text-decoration: underline;
}

/* CSS for Mobile View */
@media only screen and (max-width: 600px) {
    #main-signup-container {
        max-width: 100%;
    }

    #signup-container {
        padding: 20px;
    }

    #signup-title {
        font-size: 25px;
        padding-top: 1%;
    }
}